<template>
  <a-card>
    <div>
      <div class="table-operator">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="12" :sm="24">
              <a-form-item label="角色名称">
                <a-input
                  v-model="queryParam.name"
                  placeholder="请输入"
                  @change="() => {}"
                />
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24" style="text-align: right">
              <span class="table-page-search-submitButtons" :style="{}">
                <a-button
                  style="margin-left: 8px"
                  @click="() => (this.queryParam = {})"
                  >重置
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="add">新建</a-button>
      </div>
      <add-role-modal
        @close="() => (this.createVisible = false)"
        v-bind:visible="createVisible"
      />
      <a-spin :spinning="loading">
        <a-table
          :columns="columns"
          :dataSource="this.pageData"
          rowKey="uuid"
          :pagination="pagination"
          @change="changePageSize"
        >
          <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <a @click="edit(record)">编辑</a>&nbsp;&nbsp;
            <a @click="showConfirm(record)">删除</a>
          </span>
        </a-table>
      </a-spin>
    </div>
  </a-card>
</template>

<script>
import { modal } from "ant-design-vue";
import { mapActions, mapGetters } from "vuex";
import {
  DELETE_ROLE,
  GET_ROLE_ACTIONS,
  GET_ROLES
} from "@/store/modules/actions.type";
import AddRoleModal from "./detail/addRoleModal";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { RoleTypes } from "../../store/modules/roles";

export default {
  name: "roleV2Management",
  components: {
    AddRoleModal
  },
  data() {
    return {
      page: 1,
      pageSize: 20,

      createVisible: false,
      record: null,
      loading: false,

      // 查询参数
      queryParam: {
        name: ""
      },
      // 表头
      columns: [
        {
          title: "角色名称",
          dataIndex: "name"
        },
        {
          title: "角色说明",
          dataIndex: "description"
        },
        {
          title: "角色类型",
          dataIndex: "roleType",
          customRender: (text, row) => {
            switch (row.roleType) {
              case "FRONT_END":
                return "前台";
              default:
                return "后台";
            }
          }
        },
        {
          title: "权限汇总",
          customRender: (text, row) => {
            return row.actions.length;
          }
        },
        {
          title: "操作",
          dataIndex: "action",
          width: "300px",
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  created: async function() {
    this.loading = true;
    const toLoad = [];
    toLoad.push(this.getRoles(RoleTypes.USER_ROLE));
    if (this.actions.length === 0) {
      toLoad.push(this.getRoleActions());
    }
    await Promise.all(toLoad);
    this.loading = false;
    Bus.$on("getTarget", () => {
      this.$router.push({
        path: "/authorization/roles"
      });
    });
  },
  computed: {
    ...mapGetters("roles", ["roles", "actions"]),
    filteredRoles: function() {
      const name = this.queryParam.name || "";
      return (this.roles.get(RoleTypes.USER_ROLE) || []).filter(role =>
        role.name.includes(name)
      );
    },
    pageData: function() {
      const start = (this.page - 1) * this.pageSize;
      return this.filteredRoles.slice(start, start + this.pageSize);
    },
    pagination: function() {
      return defaultPagination(() => {}, this.filteredRoles.length);
    }
  },
  methods: {
    ...mapActions("roles", [GET_ROLES, DELETE_ROLE, GET_ROLE_ACTIONS]),

    changePageSize(val) {
      this.page = val.current;
      this.pageSize = val.pageSize;
    },
    add() {
      this.createVisible = true;
    },
    edit(val) {
      this.$router.push({
        path: "/authorization/roles/" + val.uuid
      });
    },
    showConfirm(val) {
      let that = this;
      modal.confirm({
        title: "确定要删除该角色吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {
          return that.deleteRole(val.uuid);
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style scoped></style>
