var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建角色","width":640,"visible":_vm.visible,"confirmLoading":_vm.loading,"destroyOnClose":""},on:{"ok":_vm.onSubmit,"cancel":_vm.onCancel}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"角色名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '请输入' }] }
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '请输入' }] }\n          ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"角色说明","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description',
            { rules: [{ required: true, message: '请输入' }] }
          ]),expression:"[\n            'description',\n            { rules: [{ required: true, message: '请输入' }] }\n          ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }